import { DateTime } from "luxon";

export const InputRules = {
  required: (val: unknown) => val != null || "必須項目です",
  isNotEmpty: (val: unknown) => !!val || "必須項目です",
  maxLenght: (length: number) => {
    return (val: unknown) => {
      if (typeof val === "string") {
        return val.length <= length || `${length}文字以内で入力してください`;
      }
      return true;
    };
  },
  time: (val: unknown) => {
    const interval = 15;
    if (typeof val === "string") {
      if (val === "") return true;
      const dt = DateTime.fromSQL(val);
      if (dt.isValid) {
        if (dt.minute % interval !== 0) {
          return `${interval}分間隔で入力してください。`;
        }
        return true;
      } else {
        return "時間の形式が不正です。";
      }
    }
  },
  timenullok: (val: unknown) => {
    const regexp = /\d\d:\d\d/;
    if (typeof val === "string") {
      if (val === "") return true;
      if (regexp.test(val)) {
        return true;
      } else {
        return "時間の形式が不正です。";
      }
    } else if (val == null) {
      return true;
    } else {
      return "時間の形式が不正です。";
    }
  },
  digit: (digit: number) => {
    return (val: unknown) => {
      if (typeof val !== "number" && val !== "")
        return "数値を入力してください";
      const numbers = val.toString().split(".");
      if (numbers[0].length > digit) return `${digit}桁以内で入力してください`;
      return true;
    };
  },
  scale: (scale: number) => {
    return (val: unknown) => {
      if (typeof val !== "number" && val !== "")
        return "数値を入力してください";
      const numbers = val.toString().split(".");
      if (numbers[1]?.length > scale)
        return `小数は${scale}以内で入力してください`;
      return true;
    };
  },
  decimal: (digit: unknown, scale: unknown) => {
    return (val: unknown) => {
      if (typeof val !== "number" && val !== "") return true;
      if (typeof digit === "number" && typeof scale === "number") {
        const numbers = val.toString().split(".");
        if (numbers[0].length > digit - scale)
          return `${digit - scale}桁以内で入力してください`;
        if (numbers[1]?.length > scale)
          return `小数は${scale}以内で入力してください`;
      } else if (typeof digit === "number") {
        const numbers = val.toString().split(".");
        if (numbers[0].length > digit)
          return `${digit}桁以内で入力してください`;
      } else if (typeof scale === "number") {
        const numbers = val.toString().split(".");
        if (numbers[1]?.length > scale)
          return `小数は${scale}以内で入力してください`;
      }
      return true;
    };
  },
  select: (val: []) => {
    if (val.length == 0) {
      return "1つ以上選択してください";
    } else {
      return true;
    }
  }
} as const;
